import { useState, Fragment, useRef, useEffect, ReactElement } from 'react';
import Select, {
  components,
  MultiValueGenericProps,
  ControlProps,
  MenuListProps,
  GroupBase,
} from 'react-select';
import type { MultiValue } from 'react-select';
import { Checkbox } from '../checkbox';
import { GImage } from '@/Components/g-image';

import { commonStyles } from './commonStyles';
import stylesModule from './search-panel.module.css';

import { TDropDownItem } from './types';

interface DropdownMultivalueProps {
  id: string;
  selectedOptions: MultiValue<TDropDownItem>;
  setSelectedOptions: Function;
  options: Array<TDropDownItem>;
  dropdownName: string;
  placeholder: string;
  icon: string | ReactElement<any, any>;
  emptyOption?: boolean;
  hideClearAll?: boolean;
}

const MenuList = (props: MenuListProps<TDropDownItem, true>) => {
  // @ts-ignore
  const { clearAllItems } = props.selectProps;

  return (
    <Fragment>
      <div
        className="text-teal-800 text-right p-2 cursor-pointer border-b"
        onClick={clearAllItems}
      >
        clear all
      </div>
      <components.MenuList<TDropDownItem, true, GroupBase<TDropDownItem>>
        {...props}
      >
        {props.children}
      </components.MenuList>
    </Fragment>
  );
};

const MultiValue = (props: MultiValueGenericProps<TDropDownItem>) => {
  // @ts-ignore
  const { selectedOptions } = props.selectProps;

  return (
    <div>
      {props.data.value === selectedOptions[0]?.value ? (
        <div className="flex items-center gap-1.5">
          {selectedOptions[0].image ? (
            <div className="w-5 h-5">
              <GImage
                path={selectedOptions[0].image}
                alt={selectedOptions[0].title}
                classes="rounded-full"
                width="100%"
                height="100%"
                crop_w={300}
                crop_h={300}
              />
            </div>
          ) : null}
          <div>
            {selectedOptions.length > 1 ? (
              <div className="max-w-full max-h-6 overflow-hidden whitespace-nowrap text-sm">
                {selectedOptions[0].title} & {selectedOptions.length - 1}{' '}
                more...
              </div>
            ) : (
              <div className="text-sm">{selectedOptions[0].title}</div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const Control = ({ children, ...props }: ControlProps<TDropDownItem, true>) => {
  // @ts-ignore
  const { icon } = props.selectProps;

  return (
    <components.Control {...props}>
      {typeof icon === 'string' ? (
        <i className={`${icon} ml-2 mr-1 text-xl`}></i>
      ) : (
        <div className="ml-2 mr-1">{icon}</div>
      )}
      {children}
    </components.Control>
  );
};

function renderOptionWithImage(
  item: TDropDownItem,
  selectedOptions: MultiValue<TDropDownItem>
): JSX.Element {
  return (
    <div className="flex justify-start gap-4 items-center">
      {item.image ? (
        <div className="flex">
          <GImage
            path={item.image}
            alt={item.title}
            classes={
              'rounded-full' + (item.comingSoon ? ' filter grayscale' : '')
            }
            width="4rem"
            height="100%"
            crop_w={300}
            crop_h={300}
          />
        </div>
      ) : null}
      <div className="flex flex-col flex-shrink">
        <div className="flex items-center">
          <h2
            className="flex items-center gap-4 text-left text-base font-bold text-dark-900 leading-5 mb-1.5"
            style={{ whiteSpace: 'break-spaces' }}
          >
            {item.title}
          </h2>
          {item.comingSoon ? (
            <div className="text-2xs bg-red-900 text-light-600 px-1 ml-2 rounded-sm mb-1.5">
              {item.comingSoon}
            </div>
          ) : null}
        </div>
        {item.description ? (
          <div
            className={`${stylesModule.searchPanel}`}
            style={{ whiteSpace: 'break-spaces' }}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        ) : null}
      </div>
      {!item.comingSoon ? (
        <div className="pr-2 flex flex-1 justify-end">
          <Checkbox
            isChecked={
              Boolean(
                selectedOptions.find((elem) => {
                  return elem.value === item.value;
                })
              ) || false
            }
          />
        </div>
      ) : null}
    </div>
  );
}

function renderOptionWithoutImage(
  item: TDropDownItem,
  selectedOptions: MultiValue<TDropDownItem>
): JSX.Element {
  return (
    <div className={'flex justify-start gap-4 items-center py-1 px-2.5'}>
      <div className="flex flex-col flex-shrink">
        <h2
          className="flex items-center gap-4 text-left text-base font-bold text-dark-900 leading-5"
          style={{ whiteSpace: 'break-spaces' }}
        >
          {item.title}
        </h2>
      </div>
      <div className="pr-2 flex flex-1 justify-end">
        <Checkbox
          isChecked={
            Boolean(
              selectedOptions.find((elem) => {
                return elem.value === item.value;
              })
            ) || false
          }
        />
      </div>
    </div>
  );
}

function DropdownMultivalue({
  id,
  options,
  dropdownName,
  placeholder,
  selectedOptions,
  setSelectedOptions,
  icon,
  emptyOption = false,
  hideClearAll = false,
}: DropdownMultivalueProps): JSX.Element {
  const handleChange = (options: MultiValue<TDropDownItem>) => {
    setSelectedOptions(options);
  };
  const clearAllItems = (e: React.MouseEvent<HTMLElement>) => {
    setSelectedOptions([]);
  };

  function renderOptions(item: TDropDownItem) {
    return item.image
      ? renderOptionWithImage(item, selectedOptions)
      : renderOptionWithoutImage(item, selectedOptions);
  }

  const styles = options[0]?.image
    ? {
        ...commonStyles,
        container: (base: any) => ({
          ...base,
          flex: '1',
        }),
        control: (base: any) => ({
          ...base,
          padding: '0.625rem 0.2rem',
          boxShadow: 'none',
          border: '1px solid #ccc',
          '&:hover': {
            border: '1px solid #ccc',
          },
        }),
        placeholder: (base: any) => ({
          ...base,
          textAlign: 'left',
        }),
        option: (base: any, state: any) => ({
          ...base,
          cursor: 'pointer',
          padding: '1rem 0.625rem',
          backgroundColor:
            state.isSelected || state.isFocused ? '#f5faff' : 'transparent',
        }),
      }
    : emptyOption
    ? {
        ...commonStyles,
        container: (base: any) => ({
          ...base,
          flex: '1',
        }),
        control: (base: any) => ({
          ...base,
          padding: '0.625rem 0.2rem',
          boxShadow: 'none',
          border: '1px solid #ccc',
          '&:hover': {
            border: '1px solid #ccc',
          },
        }),
        placeholder: (base: any) => ({
          ...base,
          textAlign: 'left',
        }),
      }
    : commonStyles;

  return (
    <Select
      id={id}
      instanceId={id}
      components={
        hideClearAll
          ? { MultiValue, Control }
          : { MultiValue, Control, MenuList }
      }
      styles={styles}
      value={selectedOptions}
      isMulti
      isSearchable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={handleChange}
      // isLoading={isLoading} TODO: add when data is available
      name={dropdownName}
      options={options}
      formatOptionLabel={renderOptions}
      placeholder={placeholder}
      // custom props
      // @ts-ignore
      clearAllItems={clearAllItems}
      selectedOptions={selectedOptions}
      icon={icon}
      blurInputOnSelect={false}
    />
  );
}

export { DropdownMultivalue };

export const commonStyles = {
  control: (base: any) => ({
    ...base,
    boxShadow: 'none',
    border: '1px solid #ccc',
    '&:hover': {
      border: '1px solid #ccc',
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0 0.3rem',
    flexWrap: 'nowrap',
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: 'transparent',
    fontSize: '0.9rem',
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    display: 'none',
  }),
  option: (base: any, state: any) => ({
    ...base,
    cursor: 'pointer',
    backgroundColor:
      state.isSelected || state.isFocused ? '#f5faff' : 'transparent',
  }),
  group: (base: any, state: any) => ({
    ...base,
    cursor: 'pointer',
    backgroundColor:
      state.isSelected || state.isFocused ? '#f5faff' : 'transparent',
  }),
  indicatorSeparator: (base: any) => ({ ...base, background: 'transparent' }),
  menu: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    display: 'none',
  }),
};

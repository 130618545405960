interface CheckboxProps {
  isChecked: boolean;
  small?: boolean;
  smallest?: boolean;
  change?: any;
  fillTheme?: boolean;
}

function Checkbox({
  isChecked,
  small = false,
  smallest = false,
  change,
  fillTheme,
}: CheckboxProps): JSX.Element {
  return (
    <div onClick={change}>
      {isChecked ? (
        <svg
          width={small ? '16' : smallest ? '14' : '20'}
          height={small ? '16' : smallest ? '14' : '20'}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.03997 10.9333C6.9333 10.9333 6.77331 10.88 6.66664 10.7733L3.78665 7.89334C3.57331 7.68 3.57331 7.41333 3.78665 7.2C3.99998 6.98667 4.26664 6.98667 4.47997 7.2L6.98665 9.70667L14.6666 2.02667C14.88 1.81334 15.1466 1.81334 15.36 2.02667C15.5733 2.24 15.5733 2.50667 15.36 2.72L7.30663 10.72C7.30663 10.88 7.14663 10.9333 7.03997 10.9333Z"
            fill="#0083A0"
          />
          <path
            d="M1.38667 16C0.58667 16 0 15.36 0 14.6133V1.38667C0 0.586667 0.640003 0 1.38667 0H9.81333C10.1333 0 10.3467 0.213333 10.3467 0.48C10.3467 0.746667 10.1333 0.96 9.86667 0.96H1.70666C1.27999 0.96 0.959993 1.28 0.959993 1.70667V14.3467C0.959993 14.7733 1.27999 15.0933 1.70666 15.0933H14.3467C14.7733 15.0933 15.0933 14.7733 15.0933 14.3467V8.05333C15.0933 7.78667 15.3067 7.57333 15.5733 7.57333C15.84 7.57333 16.0533 7.78667 16.0533 8.05333V14.56C16.0533 15.36 15.4133 15.9467 14.6667 15.9467L1.38667 16Z"
            fill="#0083A0"
          />
        </svg>
      ) : (
        <svg
          width={small ? '16' : smallest ? '14' : '20'}
          height={small ? '16' : smallest ? '14' : '20'}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="19"
            height="19"
            rx="2.5"
            fill="white"
            stroke={fillTheme ? '#0083A0' : '#292C2F'}
          />
        </svg>
      )}
    </div>
  );
}

export { Checkbox };

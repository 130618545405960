import { BasicTrip } from '@/Lib/types/trip';
import { ProductCard, ProductCardSkeleton } from '../cards/product-card';
import { MutableRefObject, ReactNode } from 'react';
import { GImage } from '../g-image';
import { SuperliteProductCard } from '../superlite-card/superlite-product-card';
import { useDevice } from '@/Lib/hooks/useDevice';

interface TripsProps {
  title: string;
  content?: ReactNode;
  trips?: BasicTrip[];
  isLoading?: boolean;
  hasSmallerBottomMarginOnTitle?: boolean;
  clickProductCard?: any;
  FallbackWhenNoTrips?: ReactNode;
  faqPage?: boolean;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  allowSuperlite?: boolean;
}

function Trips({
  title,
  content,
  trips,
  isLoading = false,
  hasSmallerBottomMarginOnTitle = false,
  clickProductCard,
  FallbackWhenNoTrips,
  faqPage,
  containerRef,
  allowSuperlite = false,
}: TripsProps): JSX.Element {
  const { isBase } = useDevice();

  const cardsCount = trips?.length
    ? trips[0].division.toLowerCase() === 'working holiday' && !isBase
      ? trips.length + 1
      : trips.length
    : 10;

  const shouldShowSuperlite = trips?.length
    ? trips[0].division.toLowerCase() === 'working holiday'
    : false;

  const loadingGridTemplateColumns = `repeat(10, 16.25rem)`;

  const gridTemplateColumns =
    shouldShowSuperlite && !isBase
      ? `repeat(3, 16.25rem) [custom-start] 22rem [custom-end] repeat(${
          cardsCount - 4
        },16.25rem)`
      : `repeat(${cardsCount}, 16.25rem)`;

  return (
    <>
      <div>
        {(isLoading || FallbackWhenNoTrips || (trips && trips.length > 0)) && (
          <>
            <h2
              className={
                'text-3xl leading-8 font-bold mx-5 md:mx-0 text-dark-900' +
                (hasSmallerBottomMarginOnTitle ? ' mb-2' : ' mb-5') +
                (faqPage ? ' !leading-3rem ' : '')
              }
            >
              {title}
            </h2>
            {content ? <>{content}</> : null}
          </>
        )}
        {!isLoading && trips && trips.length <= 0 && FallbackWhenNoTrips
          ? FallbackWhenNoTrips
          : null}
        <div
          className="max-w-6.75xl mx-auto px-5 md:px-0 grid gap-5 overflow-scroll pb-2 lg:grid-cols-4-1fr lg:overflow-visible hide-webkit-scrollbar"
          style={{
            gridTemplateColumns: isLoading
              ? loadingGridTemplateColumns
              : gridTemplateColumns,
            scrollBehavior: 'smooth',
            WebkitOverflowScrolling: 'touch',
          }}
          ref={containerRef}
        >
          {!isLoading && trips
            ? trips.map((trip, index) => (
                <>
                  {allowSuperlite &&
                    trip.division.toLowerCase() === 'working holiday' &&
                    index === 3 &&
                    !isBase && <SuperliteProductCard />}
                  <ProductCard
                    trip={trip}
                    key={trip.id}
                    clickProductCard={() =>
                      clickProductCard && clickProductCard(index, trip)
                    }
                  />
                </>
              ))
            : null}
          {isLoading
            ? [...Array(4)].map((_, index) => (
                <ProductCardSkeleton key={index} />
              ))
            : null}
        </div>
      </div>
      {isBase && allowSuperlite && (
        <div className="w-full h-full max-w-6.75xl mx-auto px-5 flex my-8">
          <SuperliteProductCard
            largeHeading
            largeTitleGap={true}
            phonesClassName="!w-[20rem]"
          />
        </div>
      )}
    </>
  );
}

export { Trips };

import { gql } from '@apollo/client';

export const GET_BLOGPOST_QUERY = gql`
  query PostsQuery($limit: Int, $order: String, $tags: [String]) {
    posts(limit: $limit, order: $order, tags: $tags) {
      title
      meta_description
      published_at
      feature_image
      url
      primary_tag {
        name
        url
      }
      tags {
        name
        url
      }
    }
  }
`;

import { getInternalName } from './get-internal-name';

export class BannerPathFactory {
  static fromDivision(type: string) {
    return `website/division/${getInternalName(type)}/banner.jpg`;
  }

  static fromDestination(name: string) {
    return `website/destination/${getInternalName(name)}/banner.jpg`;
  }

  static cityfromDestination(destinationName: string, cityName: string) {
    return `website/destination/${getInternalName(
      destinationName
    )}/${getInternalName(cityName)}.jpg`;
  }
}

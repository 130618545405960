import { gql } from '@apollo/client';

export const DESTINATIONS_QUERY = gql`
  query DestinationPageQuery {
    destinations {
      name
      internalName
    }
  }
`;

export const DESTINATION_PAGES_MAPPING = gql`
  query DestinationPagesMapping {
    pages {
      destinationPagesMapping {
        internal_name
        url
      }
    }
  }
`;
